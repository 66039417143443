import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

interface StyledProps {
  isActive: boolean;
}

export const AccordionStyled = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const AccordionItem = styled('div')`
  display: flex;
  flex-direction: column;
  height: fit-content;
  background: ${commonColors.grey50};
  padding: 12px;
  border-radius: 8px;
`;

export const AccordionTitle = styled('div')<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  height: 24px;
  background: ${commonColors.grey50};

  h1 {
    color: ${commonColors.grey900};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.08px;
  }

  svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    transform: ${({ isActive }) => (isActive ? 'rotate(180deg)' : 'unset')};

    path {
      fill: ${commonColors.grey900};
    }
  }
`;

export const AccordionList = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 10px;
`;

export const AccordionDescription = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    color: ${commonColors.grey900};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.07px;
    min-width: 100px;
  }
`;

export const CurrencyStyled = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 8px;
  border: 1px solid ${commonColors.grey300};
  background: ${commonColors.white};

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const AccordionSection = styled('div')`
  display: flex;
  flex-direction: column;

  > h2 {
    height: 22px;
    width: 100%;
    background: ${commonColors.lemonPie};
    margin-bottom: 8px;
    //font styles
    color: ${commonColors.grey900};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.08px;
  }
`;
