import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

interface StyledProps {
  isScaleIncrease: boolean;
}

export const Wrapper = styled('div')`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const Container = styled('div')<StyledProps>`
  display: flex;
  flex-direction: column;
  background: ${commonColors.grey200};
  height: calc(100% - 56px);
  margin-top: 56px;

  ::-webkit-scrollbar {
    display: block;
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${commonColors.grey500};
    border-right: none;
    border-left: none;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  ::-webkit-resizer,
  ::-webkit-scrollbar-button,
  ::-webkit-scrollbar-corner {
    display: none;
  }
`;

export const ButtonSection = styled('div')`
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 1000;
  padding: 8px 16px;
  background: ${commonColors.grey200};

  > div {
    display: flex;
    align-items: center;
    gap: 8px;

    > button {
      all: unset;
      box-sizing: border-box;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 8px;
      border: 1px solid ${commonColors.grey100};
      background: ${commonColors.white};

      &:disabled {
        background: ${commonColors.grey500};
        cursor: not-allowed;
      }
    }
  }

  > button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
  }
`;
