import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled('section')`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  gap: 32px;
  margin-bottom: 40px;
  background: ${commonColors.white};
`;

export const Container = styled('div')`
  padding: 0 16px;
`;
