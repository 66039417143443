import styled from '@emotion/styled';
import { StyledType } from 'application/constants/deal';
import { commonColors } from 'application/theme';

interface StyledProps {
  styledType: StyledType;
}

export const ButtonStyled = styled('button')<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  padding: 6px 24px;
  border-radius: 100px;
  cursor: pointer;
  border: 1px solid
    ${({ styledType }) =>
      styledType === StyledType.active
        ? commonColors.blue700
        : commonColors.grey200};
  background: ${({ styledType }) =>
    styledType === StyledType.active
      ? commonColors.blue50
      : commonColors.white};
  //font styles
  color: ${({ styledType }) =>
    styledType === StyledType.active
      ? commonColors.blue900
      : commonColors.grey700};
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: ${({ styledType }) =>
    styledType === StyledType.active ? 500 : 400};
  line-height: 140%;
  letter-spacing: 0.08px;
`;
