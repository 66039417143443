import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore, combineReducers, AnyAction } from '@reduxjs/toolkit';
import { errorSlice } from 'common';

import dealSlice from './Deal/dealSlice';
import authSlice from './Auth/authSlice';
import requestSlice from './request/slice';
import previewerPDFSlice from './PreviewerPDF/previewerPDFSlice';

const reducer = combineReducers({
  error: errorSlice.reducer,
  deal: dealSlice,
  auth: authSlice,
  request: requestSlice,
  previewerPDF: previewerPDFSlice,
});

const rootReducer: typeof reducer = (
  state: RootState | undefined,
  action: AnyAction
) => {
  return reducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
