import { InternetConnectionIcon } from 'application/assets';
import React, { FC } from 'react';

import { Wrapper, Container, IconStyled } from './styles';
import { Button } from 'application/components';
import { NoInternetConnectionProps } from './models';

const NoInternetConnection: FC<NoInternetConnectionProps> = ({ offline }) => {
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <Wrapper offline={offline}>
      <Container>
        <IconStyled>
          <InternetConnectionIcon />
        </IconStyled>
        <h1>Oops!</h1>
        <p>
          <span>No Internet connection was found.</span>
          <span>Check your connection or try again.</span>
        </p>
      </Container>

      <Button onClick={refreshPage}>Try again</Button>
    </Wrapper>
  );
};

export default NoInternetConnection;
