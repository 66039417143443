import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const ModalStyled = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  flex-direction: column;
  width: 344px;
  padding: 40px 20px;
  border-radius: 32px;
  background: ${commonColors.white};

  > h1 {
    color: ${commonColors.black};
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    color: #000;
  }
`;
