import React from 'react';

import { Currency } from 'integration/api/Deal/models';

import { EuroIcon } from 'application/assets/currency/EuroIcon';
import { PoundIcon } from 'application/assets/currency/PoundIcon';
import { DollarIcon } from 'application/assets/currency/DollarIcon';
import { SwissFrancsIcon } from 'application/assets/currency/SwissFrancsIcon';

export const getCurrencySymbol = (currency: Currency | null) => {
  switch (currency) {
    case Currency.USD:
      return '$';
    case Currency.EURO:
      return '€';
    case Currency.POUNDS:
      return '£';
    case Currency.SWISS_FRANCS:
      return '₣';
    default:
      return '';
  }
};

export const getCurrencyTitle = (currency: Currency) => {
  switch (currency) {
    case Currency.EURO:
      return 'Euro';
    case Currency.POUNDS:
      return 'Pound';
    case Currency.SWISS_FRANCS:
      return 'Swiss Francs';
    case Currency.USD:
      return 'Dollar';
    default:
      return '';
  }
};

export const getCurrencyIcon = (currency?: Currency | null) => {
  switch (currency) {
    case Currency.USD:
      return <DollarIcon />;
    case Currency.POUNDS:
      return <PoundIcon />;
    case Currency.EURO:
      return <EuroIcon />;
    case Currency.SWISS_FRANCS:
      return <SwissFrancsIcon />;
    default:
      return null;
  }
};
