import styled from '@emotion/styled';

import { commonColors } from 'application/theme';

export const FormStyled = styled('form')`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > button {
    height: 56px;
  }
`;

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;

  h1 {
    color: ${commonColors.grey900};
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  }
`;

export const FieldSection = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CurrencyStyled = styled('div')`
  min-width: 56px;
  min-height: 56px;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid ${commonColors.grey300};
  background: ${commonColors.lemonPie};
`;

export const ErrorMessage = styled('div')`
  display: flex;
  gap: 8px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid ${commonColors.red700};
  background: ${commonColors.error3};

  svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
  }

  p {
    color: ${commonColors.grey900};

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.08px;

    span {
      font-weight: 500;
    }
  }
`;
