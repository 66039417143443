import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const ButtonStyled = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  padding: 20px;
  border-radius: 35px;
  border: 2px solid ${commonColors.blue700};
  background: ${commonColors.blue700};
  cursor: pointer;

  color: ${commonColors.white};
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.08px;

  &:disabled {
    border-color: ${commonColors.grey500};
    background: ${commonColors.grey500};
  }
`;
