import React, { FC, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'application/store';
import { getDealLogo } from 'application/store/Deal/actions';
import { formatDate } from 'application/helpers/formatDate';
import { UserIcon } from 'application/assets';
import { formatTotal } from 'application/helpers/formatTotal';

import { LogoType } from 'integration/api/Deal/models';

import {
  HeaderStyled,
  ImageContainer,
  CommitmentsContainer,
  ProgressBar,
} from './styles';

const Header: FC = () => {
  const dispatch = useAppDispatch();
  const { deal } = useAppSelector((state) => state.deal);

  const [logo, setLogo] = useState('');

  const creationData = deal?.creationDate
    ? formatDate(deal?.creationDate.toString())
    : '';

  const totalFundingGoal = formatTotal(
    deal?.totalFundingGoal?.amount || 0,
    deal?.dealCurrency
  );
  const allocationLeft = formatTotal(
    deal?.allocationLeft || 0,
    deal?.dealCurrency
  );

  const progress =
    ((deal?.totalFunds || 0) * 100) / (deal?.totalFundingGoal?.amount || 0);
  const progressValue = progress > 100 ? 100 : progress;

  useEffect(() => {
    if (deal?.logoFileId) {
      dispatch(
        getDealLogo({
          id: deal.id,
          logoId: deal.logoFileId,
          logoType: LogoType.THUMBNAIL,
        })
      ).then((response) => {
        const data = response.payload as Blob;
        const logoBlob = new Blob([data], {
          type: data.type as string,
        });
        const logoUrl = URL.createObjectURL(logoBlob);
        setLogo(logoUrl);
      });
    }
  }, [deal?.logoFileId]);

  return (
    <HeaderStyled>
      {logo && (
        <ImageContainer>
          <img src={logo} alt="table-row" />
          <div>
            <h1>{deal?.title}</h1>
            <p>Added on {creationData}</p>
          </div>
        </ImageContainer>
      )}
      <CommitmentsContainer>
        <ProgressBar progress={progressValue}>
          <div>
            <p>
              <UserIcon /> {deal?.committedInvestorsNumber} commitments
            </p>
            <p>{progressValue}%</p>
          </div>
          <div className="bar">
            <div className="progress"></div>
          </div>
        </ProgressBar>
        <p>
          <span>{allocationLeft} left</span>
          from {totalFundingGoal}
        </p>
      </CommitmentsContainer>
    </HeaderStyled>
  );
};

export default Header;
