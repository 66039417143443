import React, { FC } from 'react';

import { useAppSelector } from 'application/store';
import { Description } from 'application/components';
import { AmplitudeEvent, useAmplitude } from 'application/hooks/useAmplitude';

import { Wrapper, OverviewList, ListItem } from './styles';

const ShortOverview: FC = () => {
  const { deal } = useAppSelector((state) => state.deal);
  const { trackEvent } = useAmplitude();

  const shortOverview = [
    {
      title: 'Industry:',
      value: deal?.broadCategoryName,
    },
    {
      title: 'Country:',
      value: deal?.country?.name,
    },
    {
      title: 'Website:',
      value: deal?.website,
    },
    {
      title: 'Year founded:',
      value: deal?.foundedYear,
    },
    {
      title: 'Funding Stage',
      value: deal?.stageName,
    },
    {
      title: 'Business Model',
      value: deal?.businessModelName,
    },
  ];

  const sendWebsiteEvent = () => {
    trackEvent(AmplitudeEvent.Deals_Overview_website, {
      deal_title: deal?.title,
    });
  };

  return (
    <Wrapper>
      <Description
        title={'Short overview'}
        text={deal?.overview?.description || ''}
      />
      <OverviewList>
        {shortOverview.map((item) => (
          <ListItem key={item.title}>
            <h1>{item.title}</h1>
            {item.title === 'Website:' ? (
              <a
                href={item.value}
                target="_blank"
                rel="noreferrer"
                onClick={sendWebsiteEvent}
              >
                {item.value}
              </a>
            ) : (
              <p>{item.value}</p>
            )}
          </ListItem>
        ))}
      </OverviewList>
    </Wrapper>
  );
};

export default ShortOverview;
