import axios, { AxiosError, AxiosRequestHeaders } from 'axios';

import { store } from 'application/store';
import { PathNames } from 'application/routes';
import { refreshToken } from 'application/store/Auth/actions';
import { checkTokenExpiration } from 'application/helpers/checkTokenExpiration';

import { getAuthTokenStorage } from 'common/auth/authService';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const isTokenExpired = await checkTokenExpiration();

    if (isTokenExpired) {
      const data = await store.dispatch(refreshToken());
      if (data && data.meta.requestStatus === 'fulfilled') {
        const accessToken = await getAuthTokenStorage();
        const token = accessToken
          ? accessToken.accessToken
          : store.getState().auth.tokens?.accessToken;
        return {
          ...config,
          ...{
            headers: {
              ...config.headers,
              Authorization: `Bearer ${token}`,
            } as AxiosRequestHeaders,
          },
        };
      }
    }

    // perform a task before the request is sent
    if (!config.headers) {
      config.headers = {} as AxiosRequestHeaders;
    }

    // Authorization
    const accessToken = await getAuthTokenStorage();
    const token = accessToken
      ? accessToken.accessToken
      : store.getState().auth.tokens?.accessToken;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    console.error('[API] request ERROR: ', error);
    // handle the error
    return Promise.reject(error);
  }
);

// declare a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // do something with the response data
    return response;
  },
  async (error: AxiosError) => {
    const accessToken = await getAuthTokenStorage();
    const token = accessToken
      ? accessToken.accessToken
      : store.getState().auth.tokens?.accessToken;

    if (!token && error.response?.status === 401) {
      return window.location.replace(PathNames.login);
    }
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log('Error', error.message);
    }
    console.log('[API] response ERROR: ', error);

    // handle the response error
    return Promise.reject(error);
  }
);

export default axiosInstance;
