import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  iframe {
    border-radius: 8px;
    width: 346px;
    height: 210px;
  }

  .iframe-container-div::after {
    content: '';
    width: 100%;
    height: 100%;
    min-height: 1px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .swiper-slide {
    > div:first-of-type {
      height: 240px !important;
    }
  }
`;

export const Title = styled('div')`
  width: 100%;
  background: ${commonColors.lemonPie};
  color: ${commonColors.grey900};
  font-size: 24px;
  font-style: italic;
  font-weight: 500;
  line-height: 130%;
  margin-bottom: 16px;
`;

export const Container = styled('div')`
  width: 100%;
  padding: 0 16px;
`;

export const SliderWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 16px 40px;
  background: ${commonColors.grey100};
  > div {
    width: 346px;
    height: 210px;
  }

  .swiper-slide {
    position: relative;
  }
`;
