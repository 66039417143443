import { createAsyncThunk } from '@reduxjs/toolkit';

import { authService, logOut } from 'common/auth/authService';

import { AuthAPI } from 'integration/api/Auth';
import { LoginDto } from 'integration/api/Auth/models';

import { RequestEnum } from '../request/model';

export const refreshToken = createAsyncThunk(
  `auth/${RequestEnum.refreshToken}`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await AuthAPI.refreshToken();
      authService.setAuthTokenStorage(data);
      return data;
    } catch (e) {
      logOut();
      return rejectWithValue(e);
    }
  }
);

export const signIn = createAsyncThunk(
  `auth/${RequestEnum.signIn}`,
  async ({ email, password }: LoginDto, { rejectWithValue }) => {
    try {
      const data = await AuthAPI.signIn({
        email,
        password,
      });

      authService.setAuthTokenStorage(data.data.tokens);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getInvestorAccount = createAsyncThunk(
  `user/${RequestEnum.getInvestorAccount}`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await AuthAPI.getInvestorAccount();
      return data;
    } catch (error) {
      console.error('[getInvestorAccount] Error: ', error);
      return rejectWithValue(error);
    }
  }
);
