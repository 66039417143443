import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled('section')`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  gap: 32px;
  padding: 0 16px;
  margin-bottom: 40px;
  background: ${commonColors.white};
  height: 100%;
`;

export const ButtonsSection = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;

  button {
    width: 120px;
  }
`;
