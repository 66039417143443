import { styled } from '@mui/system';
import { commonColors } from 'application/theme';

export const Wrapper = styled('div')`
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: ${commonColors.white};
`;

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  &:first-of-type {
    margin-top: 30px;
  }
`;
