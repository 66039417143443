import React, { FC } from 'react';
import { Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { CarouselStyled } from './styles';
import { SliderProps } from './models';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const Slider: FC<SliderProps> = ({ children, onSlideChange }) => {
  return (
    <CarouselStyled>
      <Swiper
        modules={[Pagination, Scrollbar, A11y]}
        pagination={{ clickable: true, dynamicMainBullets: 5 }}
        spaceBetween={50}
        slidesPerView={1}
        onSlideChange={() => onSlideChange && onSlideChange()}
      >
        {children.map((child, index) => (
          <SwiperSlide key={`slide-${index + 1}`}>{child}</SwiperSlide>
        ))}
      </Swiper>
    </CarouselStyled>
  );
};

export default Slider;
