import { ValuationType } from 'integration/api/Deal/models';

export const getValuationType = (type: ValuationType) => {
  switch (type) {
    case ValuationType.POST_MONEY:
      return 'post-money';
    case ValuationType.PRE_MONEY:
      return 'pre-money';
    default:
      return '';
  }
};
