import React, { lazy, Suspense } from 'react';
import { PathNames } from './constants';
import LoginPage from 'application/pages/LoginPage';
import ErrorPage from 'application/pages/ErrorPage';
import PreviewerPDF from 'application/pages/PreviewerPDFPage';

const HomePage = lazy(() => import('../pages/HomePage'));
const InvestPage = lazy(() => import('../pages/InvestPage'));

export const publicRouts = [
  {
    path: PathNames.login,
    title: '/',
    component: (
      <Suspense>
        <LoginPage />
      </Suspense>
    ),
  },
  {
    path: '/*',
    title: 'Error',
    component: <ErrorPage />,
  },
];

export const privateRouts = [
  {
    path: PathNames.home,
    title: '/',
    component: (
      <Suspense>
        <HomePage />
      </Suspense>
    ),
  },
  {
    path: PathNames.previewerPDF,
    title: 'PDF previewer',
    component: (
      <Suspense>
        <PreviewerPDF />
      </Suspense>
    ),
  },
  {
    path: PathNames.invest,
    title: 'Invest',
    component: (
      <Suspense>
        <InvestPage />
      </Suspense>
    ),
  },
];
