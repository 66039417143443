import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const ModalStyled = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  flex-direction: column;
  width: 344px;
  padding: 40px 20px;
  border-radius: 32px;
  background: ${commonColors.white};

  > svg {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 16px;
    top: 16px;
    cursor: pointer;

    path {
      fill: ${commonColors.grey700};
    }
  }

  > h1 {
    color: ${commonColors.black};
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
  }
`;

export const ButtonSection = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  gap: 12px;
  margin-top: 32px;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    border-radius: 35px;
    background: ${commonColors.darkPrimary};
  }
`;
