import React, { FC } from 'react';

import { ConfirmPopUp } from 'application/components';
import { useAppSelector } from 'application/store';
import { formatTotal } from 'application/helpers/formatTotal';
import { Divider } from 'application/components';

import { ConfirmModalProps } from './models';
import {
  ModalStyled,
  AmountStyled,
  CommitmentFlow,
  OvercommitmentFlow,
  Container,
  RowStyled,
} from './styles';

const ConfirmModal: FC<ConfirmModalProps> = ({
  opened,
  onCancel,
  onSubmit,
}) => {
  const { deal, commitment } = useAppSelector((state) => state.deal);

  const currency = deal?.investmentCurrency;
  const subTitle = `To the "${deal?.title}" startup.`;
  const amount = formatTotal(commitment?.amount, currency);
  const commitmentAmount = formatTotal(commitment?.commitment, currency);
  const overcommitmentAmount = formatTotal(
    commitment?.overcommitment,
    currency
  );

  return (
    <ConfirmPopUp
      opened={opened}
      title="Finalize your commitment"
      onCancel={onCancel}
      onSubmit={onSubmit}
    >
      <ModalStyled>
        {commitment?.overcommitment ? (
          <OvercommitmentFlow>
            <p>{subTitle}</p>
            <Container>
              <RowStyled className="bold">
                <h2>Confirmed commitment:</h2>
                <div>
                  <h3>{commitmentAmount}</h3>
                </div>
              </RowStyled>
              <RowStyled className="bold">
                <h2>Commitment to be confirmed by ICLUB:</h2>
                <div>
                  <h3>{overcommitmentAmount}</h3>
                </div>
              </RowStyled>
              <Divider />
              <RowStyled className="bold">
                <h2>Total:</h2>
                <div>
                  <AmountStyled>{amount}</AmountStyled>
                </div>
              </RowStyled>
            </Container>
          </OvercommitmentFlow>
        ) : (
          <CommitmentFlow>
            <AmountStyled>{amount}</AmountStyled>
            <p>{subTitle}</p>
          </CommitmentFlow>
        )}
      </ModalStyled>
    </ConfirmPopUp>
  );
};

export default ConfirmModal;
