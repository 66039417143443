import { useState } from 'react';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';

import { getResponseError } from 'application/helpers/responseHelper';
import { useAppDispatch } from 'application/store';
import { signIn } from 'application/store/Auth/actions';
import { PathNames } from 'application/routes';

import { LoginDto } from 'integration/api/Auth/models';

import { AmplitudeEvent, useAmplitude } from './useAmplitude';

export const useSignIn = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { trackEvent } = useAmplitude();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>('');

  const SignIn = async ({ email, password }: LoginDto) => {
    const key = process.env.REACT_APP_CRYPTO_JS_KEY || '';
    const parsedKey = CryptoJS.enc.Base64.parse(key);
    const parsedPassword = CryptoJS.enc.Utf8.parse(password);
    const encryptedPassword = CryptoJS.AES.encrypt(parsedPassword, parsedKey, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();

    setLoading(true);
    const response = await dispatch(
      signIn({
        email,
        password: encryptedPassword,
      })
    );
    const isResponseFulfilled = response.meta.requestStatus === 'fulfilled';
    const isResponseRejected = response.meta.requestStatus === 'rejected';

    if (isResponseFulfilled) {
      trackEvent(AmplitudeEvent.Sign_In_1, { step_status: 'Success' });
      navigate(PathNames.home);
    }
    if (isResponseRejected) {
      const errorMessage = getResponseError({
        payload: response.payload as any,
        type: '',
      });
      trackEvent(AmplitudeEvent.Sign_In_1, { step_status: 'Error' });
      setError(errorMessage);
    }
    setLoading(false);
  };

  return { SignIn, loading, error };
};
