import React, { FC } from 'react';

import { Button, PopUp } from 'application/components';

import { SuccessModalProps } from './models';
import { ModalStyled } from './styles';

const SuccessModal: FC<SuccessModalProps> = ({ opened, onSubmit }) => {
  return (
    <PopUp opened={opened} title="Thank you">
      <ModalStyled>
        <p>
          We'll contact you shortly to confirm your commitment via email, phone,
          or in person
        </p>
        <Button type="button" onClick={onSubmit}>
          Back to Deal
        </Button>
      </ModalStyled>
    </PopUp>
  );
};

export default SuccessModal;
