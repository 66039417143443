import axiosInstance from 'integration/axiosInstance';
import {
  DealCommitmentDto,
  DealCommitmentParams,
  GetDealFilePrams,
  GetDealLogoPrams,
  InvestorDealDto,
  SetInvestmentParams,
} from './models';

export const DealAPI = {
  getDeal: (businessId: string) => {
    return axiosInstance.get<InvestorDealDto>(
      `/api/investor/deal/business/${businessId}`
    );
  },
  getDealLogo: (params: GetDealLogoPrams) => {
    const { id, logoId, logoType } = params;
    return axiosInstance.get(`/api/investor/deal/${id}/logo/${logoId}`, {
      params: { logoType },
      responseType: 'blob',
      headers: {
        Accept: 'application/octet-stream',
        'Content-Type': 'application/octet-stream',
      },
    });
  },
  getDealFile: (params: GetDealFilePrams) => {
    const { id, fileId } = params;
    return axiosInstance.get(`/api/investor/deal/${id}/file/${fileId}`, {
      responseType: 'blob',
      headers: {
        Accept: 'application/octet-stream',
        'Content-Type': 'application/octet-stream',
      },
    });
  },
  getDealCommitment(params: DealCommitmentParams) {
    return axiosInstance.get<DealCommitmentDto>(
      `/api/investor/deal/${params.id}/commitment`,
      {
        params: {
          amount: params.amount,
        },
      }
    );
  },
  setInvestment(params: SetInvestmentParams) {
    return axiosInstance.post<DealCommitmentDto>(
      '/api/investor/commitment',
      params
    );
  },
};
