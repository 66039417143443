import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { RenderZoomProps, zoomPlugin } from '@react-pdf-viewer/zoom';

import { PathNames } from 'application/routes';
import { setFile } from 'application/store/PreviewerPDF/previewerPDFSlice';
import { CloseIcon, AddIcon, RemoveIcon } from 'application/assets';
import { useAppDispatch, useAppSelector } from 'application/store';

import { Wrapper, Container, ButtonSection } from './styles';

// Import the styles
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';

const scaleStep = 0.25;
const minScale = 0.5;
const maxScale = 1.75;

const PreviewerPDF: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { file } = useAppSelector((state) => state.previewerPDF);
  const [scale, setScale] = useState<number>(minScale);
  const [pdf, setPdf] = useState<Uint8Array | null>(null);
  const zoomPluginInstance = zoomPlugin();
  const { Zoom } = zoomPluginInstance;
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!file) {
      navigate(PathNames.home);
    }
  }, []);

  useEffect(() => {
    if (file) {
      const getFile = async () => {
        const uint8Array = new Uint8Array(await file.arrayBuffer());
        setPdf(uint8Array);
      };
      getFile();
    }
  }, [file]);

  const onClose = () => {
    dispatch(setFile(null));
    navigate(PathNames.home);
  };

  const handleIncrease = (
    callback: (newScale: number | SpecialZoomLevel) => void
  ) => {
    const currentScale = (scale || minScale) + scaleStep;
    setScale(currentScale);
    callback(currentScale);
  };
  const handleDecrease = (
    callback: (newScale: number | SpecialZoomLevel) => void
  ) => {
    const isMinScale = scale - scaleStep === minScale;
    const currentScale = isMinScale ? minScale : scale - scaleStep;
    setScale(currentScale);
    callback(isMinScale ? SpecialZoomLevel.PageFit : currentScale);
  };

  return (
    <Wrapper>
      {file && (
        <Container isScaleIncrease={!!scale}>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <ButtonSection>
              <div>
                <Zoom>
                  {({ onZoom }: RenderZoomProps) => (
                    <button
                      type="button"
                      onClick={() => {
                        handleIncrease(onZoom);
                      }}
                      disabled={scale >= maxScale}
                    >
                      <AddIcon />
                    </button>
                  )}
                </Zoom>
                <Zoom>
                  {({ onZoom }: RenderZoomProps) => (
                    <button
                      type="button"
                      onClick={() => {
                        handleDecrease(onZoom);
                      }}
                      disabled={scale <= minScale}
                    >
                      <RemoveIcon />
                    </button>
                  )}
                </Zoom>
              </div>
              <button type="button" onClick={onClose}>
                <CloseIcon />
              </button>
            </ButtonSection>
            {pdf && (
              <Viewer
                fileUrl={pdf}
                plugins={[zoomPluginInstance]}
                defaultScale={SpecialZoomLevel.PageFit}
              />
            )}
          </Worker>
        </Container>
      )}
    </Wrapper>
  );
};

export default PreviewerPDF;
