import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { CommonErrors } from 'application/constants/validations';
import { DownloadIClubImage, LogoIcon } from 'application/assets';
import { Button, DownloadAppModal, Input } from 'application/components';
import { useSignIn } from 'application/hooks/useSignIn';
import { useAppSelector } from 'application/store';
import { PathNames } from 'application/routes';

import { getAuthTokenStorage } from '../authService';

import { LoginData } from './types';
import {
  ButtonSection,
  ErrorMessageWrapper,
  FieldsWrapper,
  FormStyled,
  HeaderStyled,
  SignUpSection,
  Wrapper,
  ModalContent,
} from './styles';

const schema = yup
  .object({
    email: yup
      .string()
      .email(CommonErrors.emailFormat)
      .required(CommonErrors.requiredField),
    password: yup.string().required(CommonErrors.requiredField),
  })
  .required();

export const Login: FC = () => {
  const navigate = useNavigate();
  const { tokens } = useAppSelector((state) => state.auth);
  const [showModal, setShowModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<LoginData>({
    resolver: yupResolver(schema),
  });

  const disableSubmitButton =
    !!Object.keys(errors).length || !watch('email') || !watch('password');

  const { SignIn, loading, error } = useSignIn();

  const checkAuth = async () => {
    const accessToken = await getAuthTokenStorage();

    const token = accessToken ? accessToken.accessToken : tokens?.accessToken;

    if (token) {
      navigate(PathNames.home);
    }
  };

  useEffect(() => {
    checkAuth();
  }, [tokens]);

  const onSubmit = async ({ email, password }: LoginData) => {
    if (!loading) {
      await SignIn({ email, password });
    }
  };

  return (
    <Wrapper>
      <HeaderStyled>
        <h1>Welcome Back to</h1>
        <LogoIcon />
      </HeaderStyled>
      <FormStyled onSubmit={handleSubmit(onSubmit)}>
        <FieldsWrapper>
          <Input
            id="email"
            label="Enter your email"
            type="email"
            filled={!!watch('email')}
            errorMessage={errors.email?.message}
            register={register}
          />
          <Input
            id="password"
            label="Password"
            type="password"
            filled={!!watch('password')}
            errorMessage={errors.password?.message}
            register={register}
          />
          {error?.length ? (
            <ErrorMessageWrapper>
              <Typography variant="caption">{error}</Typography>
            </ErrorMessageWrapper>
          ) : null}
        </FieldsWrapper>
        <ButtonSection>
          <SignUpSection>
            <p>Don't have an account?</p>
            <button type="button" onClick={() => setShowModal(true)}>
              Sign up
            </button>
          </SignUpSection>
          <Button disabled={disableSubmitButton} type="submit">
            Continue
          </Button>
        </ButtonSection>
      </FormStyled>

      <DownloadAppModal
        opened={showModal}
        title={'Get the app to sign up'}
        onClose={() => setShowModal(false)}
      >
        <ModalContent>
          <p>
            Download the app to access the full Deal and leave your commitment.
          </p>
          <img
            height={240}
            width={303}
            src={DownloadIClubImage}
            alt="download-icon"
            referrerPolicy="no-referrer"
          />
        </ModalContent>
      </DownloadAppModal>
    </Wrapper>
  );
};
