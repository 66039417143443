import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Title = styled('div')`
  background: ${commonColors.lemonPie};
  color: ${commonColors.grey900};
  font-size: 24px;
  font-style: italic;
  font-weight: 500;
  line-height: 130%;
`;
