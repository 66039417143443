import React, { FC } from 'react';

import { DividerProps } from './models';
import { DividerStyled } from './styles';

const Divider: FC<DividerProps> = ({ className }) => {
  return <DividerStyled className={className} />;
};

export default Divider;
