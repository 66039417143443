import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import { commonColors } from 'application/theme';

interface InputStyledProps {
  isPasswordType: boolean;
  error: boolean;
  filled: boolean;
}

export const Wrapper = styled('div')`
  width: 100%;
  position: relative;
`;

export const InputStyled = styled(TextField)<InputStyledProps>`
  width: 100%;
  font-size: 12px;

  .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.Mui-focused.MuiInputBase-formControl {
    input {
      background: ${({ error }) =>
        error ? commonColors.error3 : commonColors.blue50};
    }
  }

  input {
    box-sizing: border-box;
    height: 56px;
    color: ${commonColors.grey900};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.08px;
    padding: 12px 16px;
    border-radius: 8px;
    padding-right: ${({ isPasswordType }) =>
      isPasswordType ? '48px' : '16px'};
    background: ${({ error }) =>
      error ? commonColors.error3 : commonColors.white};

    &[type='password'] {
      padding-right: 48px;
    }
  }

  .MuiInputLabel-root {
    color: ${commonColors.grey500};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.08px;

    .MuiInputLabel-formControl {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: 0.06px;
    }

    &.Mui-focused {
      transform: translate(14px, -8px) scale(0.75);
      color: ${({ error }) =>
        error ? commonColors.red700 : commonColors.blue700};
    }
  }

  .MuiOutlinedInput-notchedOutline {
    font-size: 12px;
    border-radius: 8px;
    color: ${commonColors.grey700};
    border: 1px solid
      ${({ error, filled }) =>
        error
          ? commonColors.red700
          : filled
          ? commonColors.grey700
          : commonColors.grey300};

    legend {
      font-size: unset;
    }
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    color: ${commonColors.grey700};
    border: 1px solid
      ${({ error }) => (error ? commonColors.red700 : commonColors.blue700)} !important;
  }
`;

export const ButtonStyled = styled('button')`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;

  position: absolute;
  right: 16px;
  top: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
    min-height: 24px;
    max-height: 24px;
    path {
      fill: ${commonColors.grey500};
    }
  }
`;

export const ErrorMessage = styled('p')`
  margin-top: 4px;
  color: ${commonColors.red700};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.07px;
`;
