import styled from '@emotion/styled';

import { commonColors } from 'application/theme';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 40px 16px;
  background-color: ${commonColors.white};

  h1 {
    color: ${commonColors.black};
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    margin-bottom: 8px;
  }

  p {
    color: ${commonColors.grey900};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.08px;
    margin-bottom: 32px;
  }

  a,
  button {
    text-decoration: none;
    height: 56px;
    width: 100%;
  }
`;

export const ImageWrapper = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    position: absolute;
    width: 98px;
    height: 88px;
  }

  img {
    opacity: 0.3;
  }
`;

export const Image = styled('div')`
  width: 100%;
  height: auto;
  background: lightgray;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  border-radius: 16px;
`;
