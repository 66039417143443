import { Currency } from 'integration/api/Deal/models';
import { getCurrencySymbol } from './currency';

export const formatTotal = (price = 0, currency?: Currency) => {
  const formatter = new Intl.NumberFormat('en-US');

  const symbol = currency ? getCurrencySymbol(currency) : '';

  return formatter.format(price) + symbol;
};
