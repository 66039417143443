export const getCloudStorageItem = async (key: string) => {
  const telegram = window.Telegram;
  const webApp = telegram?.WebApp;
  return await new Promise((resolve, reject) => {
    webApp.CloudStorage?.getItem(key, (error: Error, value: string) => {
      if (!error) {
        resolve(value);
      } else {
        reject(error);
      }
    });
  });
};

export const setCloudStorageItem = async (key: string, value: any) => {
  const telegram = window.Telegram;
  const webApp = telegram?.WebApp;
  await new Promise((resolve, reject) => {
    webApp?.CloudStorage?.setItem(
      key,
      JSON.stringify(value),
      (error: Error, state: boolean) => {
        if (!error && state) {
          resolve('');
        } else {
          reject(error);
        }
      }
    );
  });
};

export const removeCloudStorageItem = async (key: string) => {
  const telegram = window.Telegram;
  const webApp = telegram?.WebApp;
  await new Promise((resolve, reject) => {
    webApp?.CloudStorage?.removeItem(key, (error: Error, state: boolean) => {
      if (!error && state) {
        resolve('');
      } else {
        reject(error);
      }
    });
  });
};
