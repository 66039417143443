import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const ModalStyled = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    margin: 20px 0 32px;
    color: ${commonColors.black};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.08px;
  }
`;
