import * as amplitude from '@amplitude/analytics-browser';

export const useAmplitude = () => {
  const trackEvent = (
    name: AmplitudeEvent,
    options?: { [key: string]: any }
  ) => {
    amplitude.track(name as string, {
      ...options,
    });
  };

  return {
    trackEvent,
  };
};

export enum AmplitudeEvent {
  // Deal Stack
  Deals_Overview = 'Deals_Overview',
  Deals_Details = 'Deals_Details',
  Deals_Traction = 'Deals_Traction',
  Deals_Invest_button = 'Deals_Invest_button',
  Deals_Overview_Video = 'Deals_Overview_Video',
  Deals_Overview_Pitch = 'Deals_Overview_Pitch',
  Deals_Overview_website = 'Deals_Overview_website',
  Deals_InvestAmount_Less = 'Deals_InvestAmount_Less',
  Deals_InvestAmount_submit = 'Deals_InvestAmount_submit',
  Deals_InvestAmount_confirm = 'Deals_InvestAmount_confirm',
  Deals_InvestAmount_abandon = 'Deals_InvestAmount_abandon',
  // Sign In
  Sign_In_1 = 'Sign_In_1',
  // Other
  Help_Button = 'Help_Button',
}
