import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled('main')`
  display: flex;
  flex-direction: column;
  padding: 12px 16px 40px;
  background: ${commonColors.grey100};
  height: 100%;

  .divider {
    margin: 40px 0;
  }
`;

export const HeaderStyled = styled('header')`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  margin-bottom: 12px;

  button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    color: ${commonColors.grey900};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.07px;
  }
`;

export const IconStyled = styled('div')`
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${commonColors.white};
  background: ${commonColors.white400};

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: ${commonColors.grey900};
    }
  }
`;

export const InfoSection = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  p {
    color: ${commonColors.grey900};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.08px;
  }
`;

export const Title = styled('div')`
  background: ${commonColors.lemonPie};
  color: ${commonColors.grey900};
  font-size: 24px;
  font-weight: 500;
  line-height: 130%;
`;
