import styled from '@emotion/styled';

export const CarouselStyled = styled('div')`
  position: relative;
  width: 340px;

  .swiper {
    height: 240px;
    display: flex;
    align-items: center;
    position: unset;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -20px;
  }
`;
