import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

interface ProgressBarProps {
  progress: number;
}

export const HeaderStyled = styled('header')`
  display: flex;
  flex-direction: column;
  padding: 34px 16px 24px;
  border-radius: 0px 0px 32px 32px;
  background: ${commonColors.grey200};
  margin-bottom: 40px;
`;

export const ImageContainer = styled('div')`
  display: flex;
  align-items: center;

  img {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    border: 1px solid ${commonColors.grey300};
    background: ${commonColors.white};
    margin-right: 8px;
  }

  > div {
    display: flex;
    flex-direction: column;
    h1 {
      color: ${commonColors.grey900};
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
      margin-bottom: 4px;
    }

    p {
      color: ${commonColors.grey700};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: 0.07px;
    }
  }
`;

export const CommitmentsContainer = styled('div')`
  display: flex;
  flex-direction: column;

  p {
    color: ${commonColors.grey700};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.07px;
    > span {
      color: ${commonColors.grey900};
      font-weight: 500;
      margin-right: 6px;
    }
  }
`;

export const ProgressBar = styled('div')<ProgressBarProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  > div:first-of-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12px 0 4px;

    p {
      display: flex;
      align-items: center;
      color: ${commonColors.grey900};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: 0.07px;

      svg {
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        margin-right: 4px;

        path {
          fill: ${commonColors.grey900};
        }
      }
    }
  }

  .bar {
    width: 100%;
    height: 4px;
    border-radius: 100px;
    background: ${commonColors.grey300};
  }

  .progress {
    width: ${({ progress }) => progress}%;
    height: 4px;
    border-radius: 100px;
    background: ${({ progress }) =>
      `linear-gradient(90deg, #0063f6 ${90.13 - progress}%, #85ff44  ${
        232.55 - progress
      }%)`};
  }
`;
