import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
`;

export const Title = styled('div')`
  width: 100%;
  background: ${commonColors.lemonPie};
  color: ${commonColors.grey900};
  font-size: 24px;
  font-style: italic;
  font-weight: 500;
  line-height: 130%;
  margin-bottom: 16px;
`;

export const ImageStyled = styled('img')`
  border-radius: 8px;
  height: 218px;
`;

export const Container = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 260px;

  .MuiCircularProgress-root {
    position: absolute;
    z-index: 10;
  }

  .react-pdf__Page {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-pdf__Page__canvas {
    max-height: 240px !important;
    max-width: 300px !important;
    height: auto !important;
    width: auto !important;
    border: 1px solid ${commonColors.grey200};
  }
`;

export const FileStyled = styled('div')`
  cursor: pointer;
`;
