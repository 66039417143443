import { ErrorIcon } from 'application/assets';
import React, { FC } from 'react';

import { Wrapper, Container, IconStyled } from './styles';
import { Button } from 'application/components';

const ErrorPage: FC = () => {
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <Wrapper>
      <Container>
        <IconStyled>
          <ErrorIcon />
        </IconStyled>
        <h1>Sorry!</h1>
        <p>
          <span>Something went wrong!</span>
          <span>Try refreshing your browser.</span>
        </p>
      </Container>

      <Button onClick={refreshPage}>Try again</Button>
    </Wrapper>
  );
};

export default ErrorPage;
