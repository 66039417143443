import React, { FC } from 'react';

import { TextEditor } from 'application/components';

import { DescriptionProps } from './modules';
import { Wrapper, Title } from './styles';

const Description: FC<DescriptionProps> = ({ title, text }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      {text && <TextEditor value={text} />}
    </Wrapper>
  );
};

export default Description;
