import styled from '@emotion/styled';

export const Wrapper = styled.div`
  p {
    margin: 0 0 8px;
    position: relative;
    user-select: text;
    white-space: pre-wrap;
    word-break: break-word;
  }
  ol {
    list-style: decimal;
    padding-inline-start: 20px;
    list-style-position: inside;
    margin: 0 0 0 16px;
    padding: 0;
  }
  ul {
    list-style: disc;
    padding-inline-start: 20px;
    list-style-position: inside;
    margin: 0 0 0 16px;
    padding: 0;
  }

  li {
    white-space: pre-line;
    margin: 8px 32px;
  }

  blockquote {
    padding-left: 16px;
    border-left: 4px solid #ced0d4;
    color: #65676b;
    font-size: 15px;
    margin: 0 0 0 20px;
    padding-left: 16px;
  }

  em {
    font-style: italic;
    caret-color: #444;
  }

  strong {
    font-weight: bold;
  }

  code {
    background-color: #f0f2f5;
    font-family: Menlo, Consolas, Monaco, monospace;
    font-size: 94%;
    padding: 1px 0.25rem;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    color: #65676b;
    font-size: 15px;
    font-weight: 700;
    margin: 10px 0 0;
    padding: 0;
    text-transform: uppercase;
  }

  a {
    color: #216fdb;
    text-decoration: none;
  }
`;
