import { store } from 'application/store';
import { getAuthTokenStorage } from 'common/auth/authService';
import dayjs from 'dayjs';

export const checkTokenExpiration = async (): Promise<boolean> => {
  const accessToken = await getAuthTokenStorage();
  const accessTokenExpiresAt = accessToken
    ? accessToken.accessTokenExpiresAt
    : store.getState().auth.tokens?.accessTokenExpiresAt;

  const expiresDate = accessTokenExpiresAt || '';
  const tokenExpiresDate = new Date(
    new Date(expiresDate).setMinutes(new Date(expiresDate).getMinutes() - 1)
  );
  const isTokenExpired = tokenExpiresDate <= new Date();
  const isDateValid = dayjs(tokenExpiresDate).diff(dayjs(), 'minute') <= 59;

  return !isDateValid || isTokenExpired;
};
