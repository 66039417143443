import React, { FC } from 'react';

import { useAppSelector } from 'application/store';

import { Wrapper, DetailsStyled, CurrencyStyled } from './styles';
import { getCurrencyIcon } from 'application/helpers/currency';
import { formatDate } from 'application/helpers/formatDate';
import { getValuationType } from 'application/helpers/getValuationType';
import { formatTotal } from 'application/helpers/formatTotal';

const DealDetails: FC = () => {
  const { deal } = useAppSelector((state) => state.deal);
  const commitmentDeadline = deal?.details?.commitmentDeadline;

  const committedAllocationAmount = formatTotal(
    deal?.details?.committedAllocationFromTaVentures?.amount
  );
  const committedAllocationCurrency =
    deal?.details?.committedAllocationFromTaVentures?.currency;

  const valuationAmount = formatTotal(deal?.details?.valuation?.amount);
  const valuationType = deal?.details?.valuation?.type
    ? getValuationType(deal?.details?.valuation?.type)
    : '';
  const valuationCurrency = deal?.details?.valuation?.currency;

  const totalRoundSizeAmount = formatTotal(
    deal?.details?.totalRoundSize?.amount
  );
  const totalRoundSizeCurrency = deal?.details?.totalRoundSize?.currency;

  const committedAllocationAmountIsNumber =
    committedAllocationAmount !== undefined &&
    committedAllocationAmount !== null;

  return (
    <Wrapper>
      {commitmentDeadline && (
        <DetailsStyled>
          <h2>Commitment deadline</h2>
          <p>{formatDate(commitmentDeadline)}</p>
          <div />
        </DetailsStyled>
      )}
      {committedAllocationAmountIsNumber && (
        <DetailsStyled>
          <h2>Committed allocation from TA Ventures</h2>
          <p>{committedAllocationAmount}</p>
          <CurrencyStyled>
            {getCurrencyIcon(committedAllocationCurrency)}
          </CurrencyStyled>
        </DetailsStyled>
      )}
      <DetailsStyled>
        <h2>Valuation</h2>
        <p>
          {valuationAmount}
          <span>{valuationType}</span>
        </p>
        <CurrencyStyled>{getCurrencyIcon(valuationCurrency)}</CurrencyStyled>
      </DetailsStyled>
      <DetailsStyled>
        <h2 className="bold">Round size</h2>
        <p>{totalRoundSizeAmount}</p>
        <CurrencyStyled>
          {getCurrencyIcon(totalRoundSizeCurrency)}
        </CurrencyStyled>
      </DetailsStyled>
    </Wrapper>
  );
};

export default DealDetails;
