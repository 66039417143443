import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ChevronLeftIcon } from 'application/assets';
import {
  getDealCommitment,
  setInvestment,
} from 'application/store/Deal/actions';
import { isResponseFulfilled } from 'application/helpers/responseHelper';
import { useAppSelector, useAppDispatch } from 'application/store';
import { PathNames } from 'application/routes';
import { Divider, Loader } from 'application/components';
import { requestSelector } from 'application/store/request/selectors';
import { AmplitudeEvent, useAmplitude } from 'application/hooks/useAmplitude';

import { Currency, SetInvestmentParams } from 'integration/api/Deal/models';

import { InvestAmount, SuccessModal, ConfirmModal } from './components';
import { InvestSchema } from './helpers/validation';

import { FormData } from './models';
import {
  Wrapper,
  HeaderStyled,
  IconStyled,
  InfoSection,
  Title,
} from './styles';

const Invest: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { deal, commitment } = useAppSelector((state) => state.deal);
  const { loading: getDealCommitmentLoading } =
    useAppSelector(requestSelector).getDealCommitment;
  const { loading: setInvestmentLoading } =
    useAppSelector(requestSelector).setInvestment;

  const { trackEvent } = useAmplitude();

  const isLoading = getDealCommitmentLoading || setInvestmentLoading;

  const [showInvestModal, setShowInvestModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const minInvest = deal?.minInvestmentAmount || 1;

  const investSchema = InvestSchema(minInvest);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormData>({
    resolver: yupResolver(investSchema),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!deal) {
      navigate(PathNames.home);
    }
  }, []);

  useEffect(() => {
    if (errors.amount?.message) {
      trackEvent(AmplitudeEvent.Deals_InvestAmount_Less, {
        deal_title: deal?.title,
      });
    }
  }, [errors.amount?.message]);

  const onBack = () => {
    navigate(-1);
  };

  const openInvestModal = () => {
    setShowInvestModal(true);
  };

  const onSubmit = async ({ amount }: FormData) => {
    if (deal) {
      const response = await dispatch(
        getDealCommitment({ id: deal.id, amount })
      );
      isResponseFulfilled(response, openInvestModal);
      trackEvent(AmplitudeEvent.Deals_InvestAmount_submit, {
        deal_title: deal?.title,
      });
    }
  };

  const handleAbandon = () => {
    setShowInvestModal(false);
    trackEvent(AmplitudeEvent.Deals_InvestAmount_abandon, {
      deal_title: deal?.title,
    });
  };

  const handleInvestmentSuccess = () => {
    setShowInvestModal(false);
    setShowSuccessModal(true);
  };

  const handleSubmitInvestment = () => {
    if (commitment && deal) {
      const params: SetInvestmentParams = {
        dealId: deal.id,
        amount: commitment.amount,
        initCommitment: commitment.commitment,
        initOvercommitment: commitment.overcommitment,
      };
      dispatch(setInvestment(params)).then((res) => {
        isResponseFulfilled(res, handleInvestmentSuccess);
      });
      trackEvent(AmplitudeEvent.Deals_InvestAmount_confirm, {
        deal_title: deal?.title,
      });
    }
  };

  const onSuccessSubmit = () => {
    navigate(PathNames.home);
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <HeaderStyled>
        <button type="button" onClick={onBack}>
          <IconStyled>
            <ChevronLeftIcon />
          </IconStyled>
          Back to Deal
        </button>
      </HeaderStyled>
      <InfoSection>
        <Title>
          Important disclaimer to know before committing your interest
        </Title>
        <p>
          We will communicate the entered amount to the startup. Please note
          that this action cannot be withdrawn later.
        </p>
      </InfoSection>
      <Divider className={'divider'} />
      <InvestAmount
        amount={watch('amount')}
        minInvest={deal?.minInvestmentAmount || 1}
        investmentCurrency={deal?.investmentCurrency || Currency.USD}
        errorMessage={errors.amount?.message}
        onSubmit={handleSubmit(onSubmit)}
        register={register}
      />
      {showSuccessModal && (
        <SuccessModal opened={showSuccessModal} onSubmit={onSuccessSubmit} />
      )}
      {showInvestModal && (
        <ConfirmModal
          opened={showInvestModal}
          onCancel={handleAbandon}
          onSubmit={handleSubmitInvestment}
        />
      )}
    </Wrapper>
  );
};

export default Invest;
