export type RequestState = {
  [name in RequestEnum]: RequestData;
};

export interface RequestData {
  loading: boolean;
  error: string;
}

export enum RequestEnum {
  //deal actions
  getDeal = 'getDeal',
  getDealLogo = 'getDealLogo',
  getDealFile = 'getDealFile',
  getDealCommitment = 'getDealCommitment',
  setInvestment = 'setInvestment',
  //auth actions
  refreshToken = 'refreshToken',
  signIn = 'signIn',
  getInvestorAccount = 'getInvestorAccount',
}
