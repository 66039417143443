import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 16px;
`;

export const OverviewList = styled('div')`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border: 1px solid ${commonColors.grey200};
  background: ${commonColors.grey50};
`;

export const ListItem = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 12px;
  min-height: 74px;
  border-bottom: 1px solid ${commonColors.grey200};

  &:first-of-type {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  &:last-of-type {
    border-bottom: 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  h1 {
    color: ${commonColors.blue900};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.07px;
    margin-bottom: 8px;
  }

  a,
  p {
    color: ${commonColors.grey900};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.08px;
  }
  a {
    cursor: pointer;
    line-height: 22px;
  }
`;
