import styled from '@emotion/styled';
import { commonColors } from 'application/theme';
import AuthBg from 'common/auth/assets/auth-bg.png';

export const Wrapper = styled('main')`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const HeaderStyled = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${AuthBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
  border-radius: 0px 0px 32px 32px;

  h1 {
    color: ${commonColors.grey900};
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 8px;
  }

  svg {
    width: 105px;
    height: 24px;
  }
`;

export const FormStyled = styled('form')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: inherit;
  margin-top: 40px;
  padding: 0 16px 16px;
`;

export const FieldsWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  gap: 20px;
`;

export const ErrorMessageWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: start;

  span {
    text-align: left;
    color: ${commonColors.red700};
  }
`;

export const ButtonSection = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  gap: 32px;

  button {
    height: 56px;
  }
`;

export const SignUpSection = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: ${commonColors.grey900};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.07px;
    margin-right: 4px;
  }

  button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;

    color: ${commonColors.blue700};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.07px;
  }
`;

export const ModalContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    color: ${commonColors.grey900};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.08px;
    margin: 8px 0 20px;
  }
`;
