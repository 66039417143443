import {
  getCloudStorageItem,
  removeCloudStorageItem,
  setCloudStorageItem,
} from 'application/helpers/cloudStorage';
import { PathNames } from 'application/routes';

export enum StorageFields {
  authTokenStorage = 'AUTH_TOKEN_STORAGE',
}

export interface authTokenStorage {
  accessToken: string;
  accessTokenExpiresAt: Date;
  refreshToken: string;
  userId: number;
}

export const getAuthTokenStorage = async () => {
  const authTokenStorage: authTokenStorage = await getCloudStorageItem(
    StorageFields.authTokenStorage
  ).then((data) => {
    if (data) {
      return JSON.parse(data as string);
    } else {
      return undefined;
    }
  });

  return {
    accessToken: authTokenStorage?.accessToken,
    refreshToken: authTokenStorage?.refreshToken,
    accessTokenExpiresAt: authTokenStorage?.accessTokenExpiresAt,
    userId: authTokenStorage?.userId,
  };
};

export const authService = {
  setAuthTokenStorage(data: authTokenStorage) {
    setCloudStorageItem(StorageFields.authTokenStorage, data);
  },
};

export const logOut = () => {
  removeCloudStorageItem(StorageFields.authTokenStorage);
  window.location.href = window.location.origin + PathNames.login;
};
