import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  OverviewIcon,
  OverviewActiveIcon,
  TractionIcon,
  TractionActiveIcon,
  ActivityIcon,
  ActivityActiveIcon,
} from 'application/assets';
import { Tabs } from 'application/constants/deal';
import { useAppDispatch, useAppSelector } from 'application/store';
import { setTab } from 'application/store/Deal/dealSlice';
import { Button, Header } from 'application/components';
import { AmplitudeEvent, useAmplitude } from 'application/hooks/useAmplitude';
import { PathNames } from 'application/routes';

import { LayoutComponentProps } from './models';
import {
  Wrapper,
  TabsStyled,
  ButtonStyled,
  StickySection,
  Container,
} from './styles';

const LayoutComponent: FC<LayoutComponentProps> = ({ children }) => {
  const navigate = useNavigate();
  const { trackEvent } = useAmplitude();
  const dispatch = useAppDispatch();
  const { tab, deal } = useAppSelector((state) => state.deal);

  const isOverviewTab = tab === Tabs.Overview;
  const isTractionTab = tab === Tabs.Traction;
  const isDetailsTab = tab === Tabs.Details;

  const handleChangeTab = (value: Tabs) => {
    dispatch(setTab(value));

    const currentEvent = AmplitudeEvent[`Deals_${value}`];
    trackEvent(currentEvent, { deal_title: deal?.title });

    window.scrollTo(0, 0);
  };

  const goToInvest = () => {
    navigate(PathNames.invest);
    trackEvent(AmplitudeEvent.Deals_Invest_button, { deal_title: deal?.title });
  };

  return (
    <Wrapper>
      <Container>
        <Header />
        <div>{children}</div>
      </Container>
      <StickySection>
        <Button type="button" onClick={goToInvest}>
          Express your interest
        </Button>
        <TabsStyled>
          <ButtonStyled
            type="button"
            isActive={isOverviewTab}
            onClick={() => handleChangeTab(Tabs.Overview)}
          >
            <div>
              {isOverviewTab ? <OverviewActiveIcon /> : <OverviewIcon />}
            </div>
            Overview
          </ButtonStyled>
          <ButtonStyled
            type="button"
            isActive={isTractionTab}
            onClick={() => handleChangeTab(Tabs.Traction)}
          >
            <div>
              {isTractionTab ? <TractionActiveIcon /> : <TractionIcon />}
            </div>
            Traction
          </ButtonStyled>
          <ButtonStyled
            type="button"
            isActive={isDetailsTab}
            onClick={() => handleChangeTab(Tabs.Details)}
          >
            <div>
              {isDetailsTab ? <ActivityActiveIcon /> : <ActivityIcon />}
            </div>
            Details
          </ButtonStyled>
        </TabsStyled>
      </StickySection>
    </Wrapper>
  );
};

export default LayoutComponent;
