import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { InitialState } from './models';

const initialState: InitialState = {
  file: null,
  isLoading: false,
};

const previewerPDFSlice = createSlice({
  name: 'previewerPDFSlice',
  initialState,
  reducers: {
    setFile: (state, action: PayloadAction<Blob | null>) => {
      state.file = action.payload;
    },
  },
});

export const { setFile } = previewerPDFSlice.actions;

export default previewerPDFSlice.reducer;
