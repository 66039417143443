import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import * as amplitude from '@amplitude/analytics-browser';

import { store } from './store';
import { AppRouter } from './routes';

import './App.css';
import theme from './theme';

const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY;
amplitude.init(AMPLITUDE_API_KEY, {
  autocapture: false,
});

const App = () => (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <AppRouter />
    </Provider>
  </ThemeProvider>
);

export default App;
