import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';

import { useAppDispatch, useAppSelector } from 'application/store';
import { LoaderSkeleton } from 'application/components';
import { getInvestorAccount } from 'application/store/Auth/actions';

import { PathNames } from './constants';
import { getAuthTokenStorage } from 'common/auth/authService';

export const ProtectedRoute: FC<{ children: ReactNode }> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { tokens, user } = useAppSelector((state) => state.auth);
  const [token, setToken] = useState('');
  const [isLogin, setIsLogin] = useState(true);

  useEffect(() => {
    const getToken = async () => {
      setIsLogin(true);
      const accessToken = await getAuthTokenStorage();

      const token = accessToken ? accessToken.accessToken : tokens?.accessToken;
      setToken(token || '');
      setIsLogin(false);
    };
    getToken();
  }, [tokens]);

  useEffect(() => {
    if (token) {
      dispatch(getInvestorAccount());
    }
  }, [token]);

  useEffect(() => {
    if (user) {
      amplitude.setUserId(user.email);
    }
  }, [user]);

  if (isLogin) {
    return <LoaderSkeleton />;
  }
  return <>{!token ? <Navigate to={PathNames.login} /> : children}</>;
};
