import React, { FC } from 'react';

import { Button } from 'application/components';
import { DownloadIClubImage, EmptyStateIcon } from 'application/assets';
import { AmplitudeEvent, useAmplitude } from 'application/hooks/useAmplitude';

import { ImageWrapper, Image, Wrapper } from './styles';

const supportLink = process.env.REACT_APP_SUPPORT_URL;

const DealNotFound: FC = () => {
  const { trackEvent } = useAmplitude();

  const sendHelpEvent = () => {
    trackEvent(AmplitudeEvent.Help_Button);
  };

  return (
    <Wrapper>
      <div>
        <ImageWrapper>
          <Image>
            <img
              height={240}
              src={DownloadIClubImage}
              alt="download-icon"
              referrerPolicy="no-referrer"
            />
          </Image>
          <EmptyStateIcon />
        </ImageWrapper>
        <h1>Deal does not exist!</h1>
        <p>
          Please contact our support team to resolve the issue and receive
          further assistance.
        </p>
      </div>
      <a href={supportLink} target="_blank" rel="noreferrer">
        <Button type="button" onClick={sendHelpEvent}>
          Contact support
        </Button>
      </a>
    </Wrapper>
  );
};

export default DealNotFound;
