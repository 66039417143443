import React, { FC } from 'react';

import { Button, Input } from 'application/components';
import { getCurrencyIcon } from 'application/helpers/currency';
import { ErrorOutlineIcon } from 'application/assets';
import { formatTotal } from 'application/helpers/formatTotal';

import {
  FormStyled,
  Container,
  FieldSection,
  CurrencyStyled,
  ErrorMessage,
} from './styles';

import { InvestmentAmountProps } from './models';

const InvestAmount: FC<InvestmentAmountProps> = ({
  amount,
  minInvest,
  investmentCurrency,
  errorMessage,
  register,
  onSubmit,
}) => {
  return (
    <FormStyled onSubmit={onSubmit}>
      <Container>
        {errorMessage && (
          <ErrorMessage>
            <ErrorOutlineIcon />
            <p>
              The minimum investment amount on this deal is{' '}
              <span>{formatTotal(minInvest, investmentCurrency)}</span>. You
              can't commit less of that amount.
            </p>
          </ErrorMessage>
        )}
        <h1>Investment amount</h1>
        <FieldSection>
          <Input
            id="amount"
            label="Enter your amount"
            type="text"
            filled={!!amount}
            register={register}
          />
          <CurrencyStyled>{getCurrencyIcon(investmentCurrency)}</CurrencyStyled>
        </FieldSection>
      </Container>
      <Button type="submit">Submit</Button>
    </FormStyled>
  );
};

export default InvestAmount;
