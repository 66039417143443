import styled from '@emotion/styled';

export const Wrapper = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  z-index: 10000;
`;
