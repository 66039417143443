import { createSlice } from '@reduxjs/toolkit';
import { getInvestorAccount, signIn } from './actions';
import { InitialState } from './models';

const initialState: InitialState = {
  tokens: null,
  registrationStatus: null,
  kycStatus: null,
  surveyFilled: false,
  isLoading: false,
  user: null,
};

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(signIn.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(signIn.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.isLoading = false;
      state.tokens = action.payload.data.tokens;
      state.registrationStatus = action.payload.data.registrationStatus;
      state.kycStatus = action.payload.data.kycStatus;
      state.surveyFilled = action.payload.data.surveyFilled;
    });
    builder.addCase(getInvestorAccount.fulfilled, (state, action) => {
      state.user = action.payload;
    });
  },
});

export default authSlice.reducer;
