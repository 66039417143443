import React, { FC, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { pdfjs } from 'react-pdf';

import { withResolvers } from 'application/helpers/withResolvers';

import { NoInternetConnection } from 'features';

import { PathNames } from './constants';
import { privateRouts, publicRouts } from './routes';
import { ProtectedRoute } from './ProtectedRoute';

withResolvers();

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

export const AppRouter: FC = () => {
  const [online, setOnline] = useState(true);

  useEffect(() => {
    window.addEventListener('online', () => setOnline(true));
    window.addEventListener('offline', () => setOnline(false));

    return () => {
      window.removeEventListener('online', () => setOnline(true));
      window.removeEventListener('offline', () => setOnline(false));
    };
  });

  return (
    <>
      {online && (
        <BrowserRouter>
          <Routes>
            {privateRouts.map(({ path, component }) => (
              <Route
                path={path}
                element={<ProtectedRoute>{component}</ProtectedRoute>}
                key={path}
              />
            ))}
            {publicRouts.map(({ path, component }) => (
              <Route path={path} element={component} key={path} />
            ))}
            <Route path="/*" element={<Navigate to={PathNames.login} />} />
          </Routes>
        </BrowserRouter>
      )}
      <NoInternetConnection offline={!online} />
    </>
  );
};
