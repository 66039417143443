export enum Currency {
  USD = 'USD',
  EURO = 'EURO',
  POUNDS = 'POUNDS',
  SWISS_FRANCS = 'SWISS_FRANCS',
}

export enum AttachmentType {
  IMAGE = 'IMAGE',
  PDF = 'PDF',
}

export enum MetricType {
  FACT = 'FACT',
  FORECAST = 'FORECAST',
}

export enum ValuationType {
  POST_MONEY = 'POST_MONEY',
  PRE_MONEY = 'PRE_MONEY',
}

export enum LogoType {
  ORIGINAL = 'ORIGINAL',
  THUMBNAIL = 'THUMBNAIL',
}

export enum TractionType {
  EBIDTA = 'EBIDTA',
  Revenue = 'Revenue',
  ARR = 'ARR',
  GMV = 'GMV',
  MRR = 'MRR',
  LTV = 'Life-time Value (LTV)',
  CAC = 'Customer Acquisition Cost (CAC)',
  AOV = 'Average Order Value (AOV)',
  NPS = 'Net promoter score (NPS)',
}

export interface AdminConfirmResetPasswordRequest {
  email: string;
  password: string;
  verificationCode: string;
}

export interface AdminProfileResponse {
  firstName: string;
  lastName: string;
  email: string;
  creationDate: string;
}

interface Total {
  amount: number;
  currency: Currency;
}

interface Video {
  id: number;
  ordinality: number;
  url: string;
}

interface TeamMember {
  id: number;
  ordinality: number;
  firstName: string;
  lastName: string;
  title: string;
  linkedinUrl: string;
  imageUrl: string;
  imageName: string;
  isResponsibleForQa: boolean;
}

export interface PitchDeckDto {
  id: number;
  ordinal: number;
  attachmentType: AttachmentType;
  attachmentPurpose: string;
  originalFileName: string;
  s3FileName: string;
}

export interface AdditionalMaterialDto {
  id: number;
  ordinal: number;
  attachmentType: AttachmentType;
  attachmentPurpose: string;
  originalFileName: string;
  s3FileName: string;
}

interface OverviewDto {
  description: string;
  problem: string;
  solution: string;
  market: string;
  videos: Video[];
  team: TeamMember[];
  pitchDecks: PitchDeckDto[];
  additionalMaterials: AdditionalMaterialDto[];
}

interface TractionYearDto {
  id: number;
  metricType: MetricType;
  tractionYear: number;
  amount: number;
  currency: Currency;
}

interface TractionMonthDto extends TractionYearDto {
  tractionMonth: number;
}

interface Details {
  commitmentDeadline: string;
  committedAllocationFromTaVentures: Total;
  valuation: {
    amount: number;
    currency: Currency;
    type: ValuationType;
  };
  totalRoundSize: Total;
}

interface Country {
  id: number;
  isoCode: string;
  alpha3: string;
  name: string;
}

export interface InvestorDealDto {
  id: number;
  title: string;
  businessId: string;
  content: string;
  logoFileId: number;
  creationDate: Date;
  investmentCurrency: Currency;
  minInvestmentAmount: number;
  totalFunds: number;
  committedInvestorsNumber: number;
  broadCategoryName: string;
  stageName: string;
  businessModelName: string;
  totalFundingGoal: Total;
  overview: OverviewDto;
  ebitda: TractionYearDto[];
  revenue: TractionYearDto[];
  mrr: TractionMonthDto[];
  arr: TractionYearDto[];
  gmv: TractionMonthDto[];
  ltv: Total;
  cac: Total;
  aov: Total;
  nps: number;
  details: Details;
  linkToQaCall: string;
  dealCurrency: Currency;
  website: string;
  foundedYear: string;
  country: Country;
  tractionOverview: string;
  allocationLeft: number;
}

export interface GetDealLogoPrams {
  id: number;
  logoId: number;
  logoType: LogoType;
}

export interface GetDealFilePrams {
  id: number;
  fileId: number;
}

export interface DealCommitmentParams {
  id: number;
  amount: number;
}

export interface DealCommitmentDto {
  amount: number;
  commitment: number;
  overcommitment: number;
}

export interface SetInvestmentParams {
  amount: number;
  dealId: number;
  initCommitment: number;
  initOvercommitment: number;
}
