import React, { FC } from 'react';
import { Modal } from '@mui/material';

import { AppStoreIcon, CloseIcon, GooglePlayIcon } from 'application/assets';
import { DownloadLink } from 'application/constants/downloadLink';

import { DownloadAppModalProps } from './models';
import { ButtonSection, ModalStyled } from './styles';

const DownloadAppModal: FC<DownloadAppModalProps> = ({
  opened,
  title,
  subTitle,
  onClose,
  children,
}) => {
  return (
    <Modal open={opened}>
      <ModalStyled>
        <CloseIcon onClick={onClose} />
        <h1>{title}</h1>
        {subTitle && <h1>{subTitle}</h1>}
        {children}
        <ButtonSection>
          <a href={DownloadLink.appStore} target={'_blank'} rel="noreferrer">
            <AppStoreIcon />
          </a>
          <a href={DownloadLink.googlePlay} target={'_blank'} rel="noreferrer">
            <GooglePlayIcon />
          </a>
        </ButtonSection>
      </ModalStyled>
    </Modal>
  );
};

export default DownloadAppModal;
