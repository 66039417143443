import React, { FC, useState } from 'react';

import { InputProps } from './models';
import { Wrapper, InputStyled, ButtonStyled, ErrorMessage } from './styles';
import { VisibilityOffIcon, VisibilityOnIcon } from 'application/assets';

const Input: FC<InputProps> = ({
  id,
  type,
  label,
  filled,
  errorMessage,
  register,
}) => {
  const [showText, setShowText] = useState(false);

  const isPasswordType = type === 'password';

  return (
    <Wrapper>
      <InputStyled
        id={id}
        label={label}
        error={!!errorMessage}
        filled={filled}
        type={showText ? 'text' : type}
        isPasswordType={isPasswordType}
        {...register(id)}
      />
      {isPasswordType && (
        <ButtonStyled
          type="button"
          onClick={() => setShowText((prev) => !prev)}
        >
          {showText ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
        </ButtonStyled>
      )}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Wrapper>
  );
};

export default Input;
