import React, { FC, useMemo, useState } from 'react';

import { ExpandMoreIcon } from 'application/assets';
import { useAppSelector } from 'application/store';
import { getCurrencyIcon } from 'application/helpers/currency';
import { formatTotal } from 'application/helpers/formatTotal';
import { getMonthByIndex } from 'application/helpers/date';
import { uniqueArray } from 'application/helpers/uniqueArray';

import { Currency, TractionType } from 'integration/api/Deal/models';

import { TractionListProps } from './models';
import {
  AccordionStyled,
  AccordionItem,
  AccordionTitle,
  AccordionList,
  AccordionDescription,
  CurrencyStyled,
  AccordionSection,
} from './styled';

const TractionList: FC<TractionListProps> = ({ metricType }) => {
  const { deal } = useAppSelector((state) => state.deal);
  const [activeTabs, setActiveTabs] = useState<TractionType[]>([]);

  const onTabClick = (type: TractionType) => {
    const opened = activeTabs.find((item) => item === type);

    setActiveTabs((prev) =>
      opened ? prev.filter((item) => item !== type) : [...prev, type]
    );
  };

  const checkIsTabActive = (type: TractionType) => {
    return activeTabs.includes(type);
  };

  const tractionYearList = useMemo(() => {
    if (!deal) return [];
    const list = [
      { name: TractionType.EBIDTA, list: [...(deal.ebitda || [])] },
      { name: TractionType.Revenue, list: [...(deal.revenue || [])] },
      { name: TractionType.ARR, list: [...(deal.arr || [])] },
    ];
    const listByMetricType = list.map((item) => ({
      ...item,
      list: item.list.filter((filed) => filed.metricType === metricType),
    }));
    const filteredList = listByMetricType.filter((item) => !!item.list.length);

    return filteredList;
  }, [deal?.ebitda, deal?.revenue, deal?.arr, metricType]);

  const tractionMonthList = useMemo(() => {
    if (!deal) return [];
    const list = [
      { name: TractionType.GMV, list: [...(deal.gmv || [])] },
      { name: TractionType.MRR, list: [...(deal.mrr || [])] },
    ];
    const listByMetricType = list.map((item) => ({
      ...item,
      list: item.list.filter((filed) => filed.metricType === metricType),
    }));
    const filteredList = listByMetricType.filter((item) => !!item.list.length);
    const sortedYearsList = filteredList
      .map((item) => item.list.map((field) => field.tractionYear))
      .flat()
      .filter(uniqueArray)
      .sort((a, b) => b - a);

    const listByYears = filteredList.map((item) => {
      return {
        ...item,
        list: sortedYearsList.map((year) => ({
          year,
          tractions: item.list.filter(
            (traction) => traction.tractionYear === year
          ),
        })),
      };
    });

    return listByYears;
  }, [deal?.gmv, deal?.mrr, metricType]);

  const tractionAmountList = useMemo(() => {
    if (!deal) return [];
    const list = [
      {
        name: TractionType.LTV,
        amount: deal.ltv?.amount,
        currency: deal.ltv?.currency,
      },
      {
        name: TractionType.CAC,
        amount: deal.cac?.amount,
        currency: deal.cac?.currency,
      },
      {
        name: TractionType.AOV,
        amount: deal.aov?.amount,
        currency: deal.aov?.currency,
      },
      { name: TractionType.NPS, amount: deal.nps, currency: '' as Currency },
    ];

    const filteredList = list.filter(
      (item) => item.amount !== null && item.amount !== undefined
    );

    return filteredList;
  }, [deal?.ltv, deal?.cac, deal?.aov, deal?.nps]);

  return (
    <AccordionStyled>
      {tractionYearList.map((item) => (
        <AccordionItem
          key={item.name}
          onClick={() => {
            onTabClick(item.name);
          }}
        >
          <AccordionTitle isActive={checkIsTabActive(item.name)}>
            <h1>{item.name}</h1>
            <ExpandMoreIcon />
          </AccordionTitle>

          {checkIsTabActive(item.name) && (
            <AccordionList className="accordion-list">
              {item.list.map((item) => (
                <AccordionDescription key={item.id}>
                  <p>{item.tractionYear}</p>
                  <p>{formatTotal(item.amount)}</p>
                  <CurrencyStyled>
                    {getCurrencyIcon(item.currency)}
                  </CurrencyStyled>
                </AccordionDescription>
              ))}
            </AccordionList>
          )}
        </AccordionItem>
      ))}
      {tractionMonthList.map((item) => (
        <AccordionItem
          onClick={() => {
            onTabClick(item.name);
          }}
        >
          <AccordionTitle isActive={checkIsTabActive(item.name)}>
            <h1>{item.name}</h1>
            <ExpandMoreIcon />
          </AccordionTitle>
          {checkIsTabActive(item.name) && (
            <AccordionList className="accordion-list">
              {item.list.map((listItem) => (
                <AccordionSection key={listItem.year}>
                  <h2>{listItem.year}</h2>
                  {listItem.tractions.map((traction) => (
                    <AccordionDescription key={traction.id}>
                      <p>{getMonthByIndex(traction.tractionMonth)}</p>
                      <p>{formatTotal(traction.amount)}</p>
                      <CurrencyStyled>
                        {getCurrencyIcon(traction.currency)}
                      </CurrencyStyled>
                    </AccordionDescription>
                  ))}
                </AccordionSection>
              ))}
            </AccordionList>
          )}
        </AccordionItem>
      ))}
      {tractionAmountList.map((item) => (
        <AccordionItem
          onClick={() => {
            onTabClick(item.name);
          }}
        >
          <AccordionTitle isActive={checkIsTabActive(item.name)}>
            <h1>{item.name}</h1>
            <ExpandMoreIcon />
          </AccordionTitle>
          {checkIsTabActive(item.name) && (
            <AccordionList className="accordion-list">
              <AccordionDescription>
                <p>{formatTotal(item.amount)}</p>
                {item.currency && (
                  <CurrencyStyled>
                    {getCurrencyIcon(item.currency)}
                  </CurrencyStyled>
                )}
              </AccordionDescription>
            </AccordionList>
          )}
        </AccordionItem>
      ))}
    </AccordionStyled>
  );
};

export default TractionList;
