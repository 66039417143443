import axios, { AxiosRequestHeaders } from 'axios';

const axiosNonTokenInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

axiosNonTokenInstance.interceptors.request.use(
  async (config) => {
    // perform a task before the request is sent
    if (!config.headers) {
      config.headers = {} as AxiosRequestHeaders;
    }

    return config;
  },
  (error) => {
    console.error('[API] request ERROR: ', error);
    // handle the error
    return Promise.reject(error);
  }
);

// declare a response interceptor
axiosNonTokenInstance.interceptors.response.use(
  (response) => {
    // do something with the response data
    return response;
  },
  (error) => {
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // console.log(error.request);
    } else {
      console.log('Error', error.message);
    }
    console.log('[API] response ERROR: ', error);
    // handle the response error
    return Promise.reject(error);
  }
);

export default axiosNonTokenInstance;
