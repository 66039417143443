import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const ModalStyled = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 12px;
`;

export const AmountStyled = styled('div')`
  padding: 4px 24px;
  width: fit-content;
  border-radius: 8px;
  background: ${commonColors.lemonPie};

  color: ${commonColors.grey900};
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
`;

export const CommitmentFlow = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  p {
    color: ${commonColors.grey700};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.08px;
  }
`;

export const OvercommitmentFlow = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;

  p {
    color: ${commonColors.grey700};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.08px;
  }
`;

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  border-radius: 8px;
  background: ${commonColors.grey200};
`;

export const RowStyled = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  h2 {
    width: 157px;
    color: ${commonColors.black};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.07px;
  }

  > div {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h3 {
    color: ${commonColors.grey900};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.08px;
  }

  .bold h2 {
    font-weight: 700;
  }
`;
