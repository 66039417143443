import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

interface StyledProps {
  isActive: boolean;
}

export const Wrapper = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${commonColors.white};
  height: 100%;
`;

export const TabsStyled = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 14px 18px;
  background-color: ${commonColors.grey200};
`;

export const ButtonStyled = styled('button')<StyledProps>`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;

  > div {
    display: flex;
    padding: 3px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background-color: ${({ isActive }) =>
      isActive ? commonColors.grey100 : 'transparent'};
    margin-bottom: 4px;
  }

  color: ${({ isActive }) =>
    isActive ? commonColors.blue700 : commonColors.grey500};
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.06px;
`;

export const StickySection = styled('div')`
  position: sticky;
  bottom: 0px;
  z-index: 50;
  width: 100%;
  display: flex;
  flex-direction: column;

  button {
    margin: 8px 16px;
  }
`;

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
`;
