import { getAuthTokenStorage } from 'common/auth/authService';
import axiosNonTokenInstance from 'integration/axiosNonTokenInstance';
import {
  InvestorAccountDto,
  LoginDto,
  SignInResponse,
  TokenDto,
} from './models';
import axiosInstance from 'integration/axiosInstance';

export const AuthAPI = {
  signIn: (data: LoginDto) => {
    return axiosNonTokenInstance.post<SignInResponse>(
      '/api/investor/sign-in/email',
      data
    );
  },
  refreshToken: async () => {
    const { refreshToken, userId } = await getAuthTokenStorage();

    return axiosNonTokenInstance.post<TokenDto>('/api/investor/refresh-token', {
      refreshToken,
      userId,
    });
  },
  getInvestorAccount: () => {
    return axiosInstance.get<InvestorAccountDto>('/api/investor/account');
  },
};
