export interface PaletteColorOptions {
  main: string;
  light: string;
  dark?: string;
}
export interface PaletteOptions {
  primary: PaletteColorOptions;
  secondary: PaletteColorOptions;
  text: PalleteTextOptions;
  success: PaletteColorOptions;
  error: PaletteColorOptions;
  warning: PaletteColorOptions;
  info: PaletteColorOptions;
  common: ColorsPallet;
}
export interface PalleteTextOptions {
  primary: string;
  secondary: string;
  disabled?: string;
}

export const colors = {
  // grey
  grey50: '#F9FAFB',
  grey100: '#F3F6FB',
  grey200: '#EBEDF2',
  grey300: '#D1D5DB',
  grey500: '#6B7280',
  grey700: '#374151',
  grey900: '#111928',

  // blue
  blue50: '#F2F7FF',
  blue600: '#007AFF',
  blue700: '#0063F6',
  blue900: '#0050C9',

  // red
  error3: '#FDF7F7',
  red700: '#FF3A30',

  // yellow
  lemonPie: '#EDFF6B',

  // black
  black: '#000000',
  darkPrimary: '#131313',

  //white
  white: '#FFFFFF',
  white400: '#ffffff66',
};

export type ColorsPallet = typeof colors;

const Palette: PaletteOptions = {
  primary: {
    main: '#0063F6',
    light: '#6D6D6D',
    dark: '#0a222a',
  },
  secondary: {
    main: '#C9C9C9',
    light: '#00B3B6',
    dark: '#818181',
  },
  text: {
    primary: '#2B2B2B',
    secondary: '#4D4D4D',
  },
  success: {
    main: '#42B271',
    light: '#0ec48c',
    dark: '#0d8253',
  },
  warning: {
    main: '#F77421',
    light: '#ffbc00',
    dark: '#f6993f',
  },
  error: {
    main: '#E82425',
    light: '#D7151F',
  },
  info: {
    main: '#2176FF',
    light: '#0099FF',
  },
  common: colors,
};
export default Palette;
