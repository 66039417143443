import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { DealAPI } from 'integration/api/Deal';
import {
  DealCommitmentParams,
  GetDealFilePrams,
  GetDealLogoPrams,
  SetInvestmentParams,
} from 'integration/api/Deal/models';
import { RequestEnum } from '../request/model';

export const getDeal = createAsyncThunk(
  RequestEnum.getDeal,
  async (businessId: string, { rejectWithValue }) => {
    try {
      const { data: responseData } = await DealAPI.getDeal(businessId);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  }
);

export const getDealLogo = createAsyncThunk(
  RequestEnum.getDealLogo,
  async (params: GetDealLogoPrams, { rejectWithValue }) => {
    try {
      const { data: responseData } = await DealAPI.getDealLogo(params);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  }
);

export const getDealFile = createAsyncThunk(
  RequestEnum.getDealFile,
  async (params: GetDealFilePrams, { rejectWithValue }) => {
    try {
      const { data: responseData } = await DealAPI.getDealFile(params);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  }
);

export const getDealCommitment = createAsyncThunk(
  RequestEnum.getDealCommitment,
  async (params: DealCommitmentParams, { rejectWithValue }) => {
    try {
      const { data: responseData } = await DealAPI.getDealCommitment(params);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  }
);

export const setInvestment = createAsyncThunk(
  RequestEnum.setInvestment,
  async (params: SetInvestmentParams, { rejectWithValue }) => {
    try {
      const { data } = await DealAPI.setInvestment(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  }
);
