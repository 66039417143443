import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Tabs } from 'application/constants/deal';

import { getDeal, getDealCommitment, setInvestment } from './actions';
import { InitialState } from './models';

const initialState: InitialState = {
  tab: Tabs.Overview,
  deal: null,
  isLoading: false,
  commitment: {
    amount: 0,
    commitment: 0,
    overcommitment: 0,
  },
};

const dealSlice = createSlice({
  name: 'dealSlice',
  initialState,
  reducers: {
    setTab: (state, action: PayloadAction<Tabs>) => {
      state.tab = action.payload;
    },
  },
  extraReducers(builder) {
    // get deal
    builder.addCase(getDeal.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDeal.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getDeal.fulfilled, (state, action) => {
      state.isLoading = false;
      state.deal = action.payload;
    });
    //get deal commitment
    builder.addCase(getDealCommitment.fulfilled, (state, action) => {
      state.commitment.amount = action.payload.amount;
      state.commitment.commitment = action.payload.commitment;
      state.commitment.overcommitment = action.payload.overcommitment;
    });
    //set investment
    builder.addCase(setInvestment.fulfilled, (state, action) => {
      state.commitment.amount = action.payload.amount;
      state.commitment.commitment = action.payload.commitment;
      state.commitment.overcommitment = action.payload.overcommitment;
    });
  },
});

export const { setTab } = dealSlice.actions;

export default dealSlice.reducer;
