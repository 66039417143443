import React, { FC, useState } from 'react';

import { useAppSelector } from 'application/store';
import { Slider } from 'application/components';

import VideoPlayer from '../VideoPlayer';

import { Wrapper, Title, Container, SliderWrapper } from './styles';

export const VideosSlider: FC = () => {
  const { deal } = useAppSelector((state) => state.deal);
  const videos = deal?.overview.videos || [];
  const [playing, setPlaying] = useState<number>(0);

  return (
    <Wrapper>
      <Container>
        <Title>Video</Title>
      </Container>
      <SliderWrapper>
        <Slider
          onSlideChange={() => {
            setPlaying((prev) => prev + 1);
          }}
        >
          {videos.map((video) => (
            <VideoPlayer
              key={video.id}
              url={video.url}
              playing={playing}
              dealTitle={deal?.title || ''}
            />
          ))}
        </Slider>
      </SliderWrapper>
    </Wrapper>
  );
};

export default VideosSlider;
