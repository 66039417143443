import React, { FC } from 'react';
import { CircularProgress } from '@mui/material';

import { Wrapper } from './styled';

const Loader: FC = () => {
  return (
    <Wrapper>
      <CircularProgress />
    </Wrapper>
  );
};

export default Loader;
