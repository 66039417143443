import React, { FC, useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'application/store';
import { Slider } from 'application/components';
import { getDealFile } from 'application/store/Deal/actions';
import { setFile } from 'application/store/PreviewerPDF/previewerPDFSlice';
import { PathNames } from 'application/routes';
import { requestSelector } from 'application/store/request/selectors';

import { AttachmentType } from 'integration/api/Deal/models';

import { FileProps, MaterialProps, MaterialsDto } from './models';
import { Wrapper, Title, ImageStyled, Container, FileStyled } from './styles';

const Image: FC<MaterialProps> = ({ image }) => {
  const [url, setUrl] = useState<string>('');

  useEffect(() => {
    const logoUrl = URL.createObjectURL(image);
    setUrl(logoUrl);
  }, [image]);

  return <ImageStyled src={url} />;
};

const File: FC<FileProps> = ({ file, setFileToPreview }) => {
  const dispatch = useAppDispatch();

  return (
    <FileStyled>
      {file && (
        <Document
          file={file}
          className={'pdf-document'}
          onClick={() => setFileToPreview(file)}
          externalLinkRel={'noreferrer'}
          externalLinkTarget={'_blank'}
        >
          <Page
            pageNumber={1}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        </Document>
      )}
    </FileStyled>
  );
};

export const AdditionalMaterials: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { deal } = useAppSelector((state) => state.deal);
  const { loading } = useAppSelector(requestSelector).getDealFile;

  const [files, setFiles] = useState<MaterialsDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (deal?.id && deal?.overview.additionalMaterials) {
      const getFiles = async () => {
        setIsLoading(true);
        const filesList = await Promise.all(
          deal?.overview.additionalMaterials.map(async (file) => {
            const response = await dispatch(
              getDealFile({
                id: deal.id,
                fileId: file.id,
              })
            );
            const data = response.payload as Blob;
            const fileBlob = new Blob([data], {
              type: data.type as string,
            });
            return {
              item: fileBlob,
              id: file.id,
              attachmentType: file.attachmentType,
            };
          })
        );
        setIsLoading(false);
        setFiles(filesList);
      };
      getFiles();
    }
  }, [deal?.overview.additionalMaterials, deal?.id]);

  const onPreview = (file: Blob | null) => {
    if (file) {
      dispatch(setFile(file));
      navigate(PathNames.previewerPDF);
    }
  };

  const getComponent = ({ item, id, attachmentType }: MaterialsDto) => {
    switch (attachmentType) {
      case AttachmentType.IMAGE: {
        return <Image key={id} image={item} />;
      }
      default: {
        return <File key={id} file={item} setFileToPreview={onPreview} />;
      }
    }
  };

  return (
    <Wrapper>
      <Title>Additional materials</Title>
      <Container>
        {(isLoading || loading) && <CircularProgress />}
        <Slider>{files.map((item) => getComponent(item))}</Slider>
      </Container>
    </Wrapper>
  );
};

export default AdditionalMaterials;
