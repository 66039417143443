import React, { FC } from 'react';

import { ButtonStyled } from './styles';
import { OutlineButtonProps } from './models';

const OutlineButton: FC<OutlineButtonProps> = ({ children, ...props }) => {
  return <ButtonStyled {...props}>{children}</ButtonStyled>;
};

export default OutlineButton;
