import React, { FC, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';

import { AmplitudeEvent, useAmplitude } from 'application/hooks/useAmplitude';

import { VideoPlayerProps } from './models';

const VideoPlayer: FC<VideoPlayerProps> = ({ url, playing, dealTitle }) => {
  const playerRef = useRef(null);
  const { trackEvent } = useAmplitude();
  const [isPlaying, setIsPlaying] = useState(false);

  const play = () => {
    trackEvent(AmplitudeEvent.Deals_Overview_Video, {
      deal_title: dealTitle,
    });
    setIsPlaying(true);
  };
  const pause = () => {
    setIsPlaying(false);
  };

  useEffect(() => {
    setIsPlaying(false);
  }, [playing]);

  return (
    <ReactPlayer
      ref={playerRef}
      url={url}
      playing={isPlaying}
      onPause={pause}
      onPlay={play}
      pip={true}
    />
  );
};

export default VideoPlayer;
