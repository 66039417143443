import React, { FC } from 'react';
import { Modal } from '@mui/material';

import { PopUpProps } from './models';
import { ModalStyled } from './styles';

const PopUp: FC<PopUpProps> = ({ opened, title, children }) => {
  return (
    <Modal open={opened}>
      <ModalStyled>
        <h1>{title}</h1>
        {children}
      </ModalStyled>
    </Modal>
  );
};

export default PopUp;
