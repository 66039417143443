import React, { FC } from 'react';

import PopUp from '../PopUp';
import Button from '../Button';
import OutlineButton from '../OutlineButton';

import { DownloadAppModalProps } from './models';
import { ButtonSection, ModalStyled } from './styles';

const DownloadAppModal: FC<DownloadAppModalProps> = ({
  opened,
  title,
  children,
  onCancel,
  onSubmit,
}) => {
  return (
    <PopUp opened={opened} title={title}>
      <ModalStyled>
        {children}
        <ButtonSection>
          <OutlineButton type="button" onClick={onCancel}>
            Abandon
          </OutlineButton>
          <Button type="submit" onClick={onSubmit}>
            Confirm
          </Button>
        </ButtonSection>
      </ModalStyled>
    </PopUp>
  );
};

export default DownloadAppModal;
