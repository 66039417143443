import React, { FC } from 'react';

import { useAppSelector } from 'application/store';
import { Description } from 'application/components';

import {
  AdditionalMaterials,
  PitchDeck,
  ShortOverview,
  Team,
  VideosSlider,
} from './components';

import { Wrapper, Container } from './styles';

const DealOverview: FC = () => {
  const { deal } = useAppSelector((state) => state.deal);

  return (
    <Wrapper>
      <ShortOverview />
      <VideosSlider />
      <Container>
        <Description title="Problem" text={deal?.overview?.problem || ''} />
      </Container>
      <Container>
        <Description title="Solution" text={deal?.overview?.solution || ''} />
      </Container>
      <Container>
        <Description title="Market" text={deal?.overview?.market || ''} />
      </Container>
      <Team />
      <PitchDeck />
      <AdditionalMaterials />
    </Wrapper>
  );
};

export default DealOverview;
