import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { Container, Wrapper } from './styles';

const LoaderSkeleton: FC = () => {
  return (
    <Wrapper>
      <Skeleton
        width={'100%'}
        height={150}
        borderRadius={' 0px 0px 32px 32px'}
      />
      <Container>
        <Skeleton height={30} width={'100%'} />
        <Skeleton height={100} width={'100%'} />
      </Container>
      <Container>
        <Skeleton height={30} width={'100%'} />
        <Skeleton height={100} width={'100%'} />
      </Container>
      <Container>
        <Skeleton height={30} width={'100%'} />
        <Skeleton height={100} width={'100%'} />
      </Container>
    </Wrapper>
  );
};

export default LoaderSkeleton;
