export enum Tabs {
  Overview = 'Overview',
  Traction = 'Traction',
  Details = 'Details',
}

export enum StyledType {
  active = 'active',
  default = 'default',
}
