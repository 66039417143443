import React, { FC, useState } from 'react';

import { Description, SecondaryButton } from 'application/components';
import { StyledType } from 'application/constants/deal';

import { TractionList } from './components';

import { Wrapper, ButtonsSection } from './styles';
import { MetricType } from 'integration/api/Deal/models';
import { useAppSelector } from 'application/store';

const DealTraction: FC = () => {
  const { deal } = useAppSelector((state) => state.deal);

  const [metricType, setMetricType] = useState(MetricType.FACT);

  const isFactType = metricType === MetricType.FACT;

  return (
    <Wrapper>
      <ButtonsSection>
        <SecondaryButton
          type="button"
          styledType={isFactType ? StyledType.active : StyledType.default}
          onClick={() => setMetricType(MetricType.FACT)}
        >
          Fact
        </SecondaryButton>
        <SecondaryButton
          type="button"
          styledType={!isFactType ? StyledType.active : StyledType.default}
          onClick={() => setMetricType(MetricType.FORECAST)}
        >
          Forecast
        </SecondaryButton>
      </ButtonsSection>

      <TractionList metricType={metricType} />
      {deal?.tractionOverview && (
        <Description title="Traction Overview" text={deal.tractionOverview} />
      )}
    </Wrapper>
  );
};

export default DealTraction;
