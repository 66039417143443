import styled from '@emotion/styled';

import { commonColors } from 'application/theme';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 40px 16px;
  background-color: ${commonColors.white};

  button {
    height: 56px;
    width: 100%;
  }
`;

export const Container = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 40px 20px;
  border-radius: 32px;
  background: ${commonColors.grey100};

  h1 {
    color: ${commonColors.grey900};
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    margin-bottom: 8px;
  }

  p {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${commonColors.grey900};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.08px;
    margin-bottom: 32px;
  }
`;

export const IconStyled = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 146px;
  height: 146px;
  padding: 36px 26px;
  border-radius: 32px;
  background: ${commonColors.white};
  margin-bottom: 20px;

  svg {
    width: 94px;
    height: 76px;
  }
`;
