import React, { FC } from 'react';

import { useAppSelector } from 'application/store';
import { LinkedInIcon } from 'application/assets';

import { Wrapper, MembersList, ListItem, Title, NameStyled } from './styles';

const Team: FC = () => {
  const { deal } = useAppSelector((state) => state.deal);

  const team = deal?.overview.team || [];
  return (
    <Wrapper>
      <Title>Team</Title>
      <MembersList>
        {team.map((item) => (
          <ListItem key={item.id}>
            <img src={item.imageUrl} alt={item.imageName} />
            <NameStyled>
              <h1>
                {item.firstName} {item.lastName}
              </h1>
              <p>{item.title}</p>
            </NameStyled>
            <a href={item.linkedinUrl} target="_blank" rel="noreferrer">
              <LinkedInIcon />
            </a>
          </ListItem>
        ))}
      </MembersList>
    </Wrapper>
  );
};

export default Team;
