import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
`;

export const DetailsStyled = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-radius: 8px;
  background: ${commonColors.grey50};

  h2 {
    width: 132px;
    color: ${commonColors.grey900};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.08px;

    &.bold {
      font-weight: 700;
    }
  }

  p {
    display: flex;
    flex-direction: column;
    width: 142px;
    color: ${commonColors.grey900};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.08px;

    span {
      color: ${commonColors.grey700};
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: 0.06px;
    }
  }

  > div {
    width: 24px;
  }
`;

export const CurrencyStyled = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 8px;
  border: 1px solid ${commonColors.grey300};
  background: ${commonColors.white};

  svg {
    width: 16px;
    height: 16px;
  }
`;
